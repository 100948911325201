import React, { useEffect, useState } from 'react';
import {
  PaymentElement,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';
import Button from '../../../../../common/Button';
import { ICheckoutFormProps } from './props';

export default function CheckoutForm({
  amount,
  numberOfCredits,
}: ICheckoutFormProps) {
  const stripe = useStripe();
  const elements = useElements();

  const [message, setMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!stripe) {
      return;
    }

    const clientSecret = new URLSearchParams(window.location.search).get(
      'payment_intent_client_secret',
    );

    if (!clientSecret) {
      return;
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent?.status) {
        case 'succeeded':
          setMessage('Payment succeeded!');
          break;
        case 'processing':
          setMessage('Your payment is processing.');
          break;
        case 'requires_payment_method':
          setMessage('Your payment was not successful, please try again.');
          break;
        default:
          setMessage('Something went wrong.');
          break;
      }
    });
  }, [stripe]);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }
    setIsLoading(true);
    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: `https://zen.seogroupsbuy.com/credits/success?credits=${numberOfCredits}`,
      },
    });
    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if (error.type === 'card_error' || error.type === 'validation_error') {
      setMessage(error.message || '');
    } else {
      setMessage('An unexpected error occurred.');
    }
    setIsLoading(false);
  };

  return (
    <form id="payment-form" onSubmit={handleSubmit}>
      <PaymentElement id="payment-element" />
      {message && (
        <div className="text-sm text-amaranth text-center mt-4">{message}</div>
      )}
      <Button
        type="primary"
        disabled={isLoading || !stripe || !elements}
        loading={isLoading}
        className="w-full mt-6"
      >
        {isLoading ? '' : `Pay $${amount} USD`}
      </Button>
      <p className="text-[9px] text-tundora w-80 mt-4">
        By placing your order, you agree to our{' '}
        <a
          href="https://unbounce.com/terms-of-service/"
          target="_blank"
          rel="noopener noreferrer"
          className="text-ubblue"
        >
          terms of service
        </a>{' '}
        and{' '}
        <a
          href="https://unbounce.com/privacy/"
          rel="noopener noreferrer"
          target="_blank"
          className="text-ubblue"
        >
          privacy policy
        </a>
        . By confirming your payment, you allow Unbounce Smart Copy to charge
        your card for this payment and future payments in accordance with their
        terms.
      </p>
    </form>
  );
}
